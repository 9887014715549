<template>
  <div>
    <h1>Account items</h1>
    <p>On this page you can view and update which items are relevant for which account.</p>
    <generate-items-modal
      name="generate-items-modal"
      title="Generate new items"
      :callback="add_data_from_store"
      :company="customer"
    />
    <add-to-company-item-modal
      name="add-to-company-item-modal"
      title="Add account item"
      :callback="add_data_callback"
      :company="customer"
      :mutate="mutateCompanyItems"
    />

    <template v-if="$store.state.user.is_employee">
      <i-table
        ref="ITable"
        :fetch-items="fetchItems"
        :fields="fields"
        :actions="actions"
        :mutate-items="mutateCompanyItems"
        app-name="company_items"
        :allowed-selectors="allowed_selectors"
        :errors="errors"
        @update:selectBy="(v) => (select_by = v)"
        @update:tableMode="(tm) => (tableMode = tm)"
      >
      </i-table>
    </template>
  </div>
</template>

<script lang="ts">
import type { AxiosResponse } from 'axios'

import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { parseItem } from '@/common/parseItem'
import useTableDefaultFields, { DefaultFieldGroups } from '@/components/table/useTableDefaultFieldsCustomer'
import { FetchCompanyItemDocument, MutateCompanyItemsDocument } from '@/graphql/generated'
import type { ItemFragment } from '@/graphql/generated'
import AddToCompanyItemModal from '@/views/company_items/AddToCompanyItemModal.vue'
import GenerateItemsModal from '@/views/company_items/GenerateItemsModal.vue'

type CompanyItemFilter = {
  customer: Customer
  item: ItemFragment
}

type Customer = {
  cmp_wwn: string
  debnr: number
  cmp_name: string
}

type CompanyItemNode = {
  node: CompanyItem
}

type CompanyItemField = {
  id: string
  company: Customer
  item: ItemFragment
  company_item_code: string
  syscreated: User
  sysmodified: Date
  syscreator: User
  sysmodifier: User
}

type CompanyItem = {
  company: string
  ItemCode: string
  Description: string
  company_item_code: string
  id: string | null
  debnr: number
  cmp_name: string
  IsStockItem: boolean
  c01: string
  c02: string
  c03: string
  c05: string
  c06: string
  c08: string
  abc: string
  leadtime: number
  assortment: string
  salesquantity: number
  minimum_order_quantity: number
  unit: string
  hs_code: string
  is_m2_item: boolean
  syscreated: Date
  syscreator: User
  sysmodified: Date
  sysmodifier: User
}

type User = {
  first_name: string
  last_name: string
  email: string
  id: string
}

export default {
  name: 'CompanyItems',
  components: { GenerateItemsModal, AddToCompanyItemModal },
  mixins: [api_mixin, utils],
  data() {
    return {
      select_by: null,
      errors: [],
      fields: [],
      extraFields: [
        {
          key: 'company_item_code',
          editable: true,
          optional: false,
          selected: true,
        },
      ],
    }
  },
  computed: {
    allowed_selectors() {
      if (this.$store.state.user.is_employee) {
        return [['customer', 'item']]
      } else {
        return []
      }
    },
    actions() {
      return [
        {
          key: 'add_data',
          execute_global: this.select_by && this.select_by.customer ? this.showAddDataModal : null,
        },
        {
          key: 'generate_new_data',
          modes: [TABLE_MODE.READ, TABLE_MODE.BULK_ADD],
          icon: 'box-arrow-in-down-left',
          variant: 'b-blue',
          title: 'Generate bulk data',
          disallow: false,
          execute_bulk: this.showGenerateItemsModal,
          always_enabled: this.customer,
        },
      ]
    },
    customer() {
      return this.select_by ? this.select_by.customer.cmp_wwn : null
    },
  },
  mounted() {
    if (this.$store.state.user.is_customer) {
      this.select_by = { selected: 'customer', customer: this.$store.state.company }
    }
    this.fields = useTableDefaultFields(
      [DefaultFieldGroups.ItemAttributes, DefaultFieldGroups.CustomerAttributes, DefaultFieldGroups.SystemInfo],
      this.extraFields
    )
  },
  methods: {
    add_data_from_store(data: CompanyItem[]) {
      data.forEach((item) => {
        item.id = null
        item.company = this.customer
      })
      const item_code_list = this.$refs.ITable.items.map((f) => f.ItemCode)
      data = data.filter((item) => !item_code_list.includes(item.ItemCode))
      this.$refs.ITable.add_data_bulk(data)
    },

    add_data_callback(data) {
      this.$refs.ITable.addItem(data)
    },
    async showAddDataModal() {
      this.$bvModal.show('add-to-company-item-modal')
    },
    mutateCompanyItems(rows: [CompanyItem], removed_rows: CompanyItem[] = []) {
      const deletion_input = removed_rows.map((row: CompanyItem) => this.parametersFromRow(row, true))
      let mutation_input = rows.map((row: CompanyItem) => this.parametersFromRow(row, false))
      mutation_input = mutation_input.concat(deletion_input)
      return this.api_call(MutateCompanyItemsDocument, { input: mutation_input }).then((response: AxiosResponse) => {
        let errors = []
        if (response.data.errors) {
          errors = errors.concat(response.data.errors)
        }
        const fields = response.data.data ? response.data.data.MutateCompanyItems.company_items : []
        return { errors: errors, successful: fields.map((item: CompanyItemField) => this.parseCompanyItemNode(item)) }
      })
    },
    async showGenerateItemsModal() {
      this.$bvModal.show('generate-items-modal')
    },

    parametersFromRow(row: CompanyItem, del = false) {
      return {
        company: row.company,
        item: row.ItemCode,
        company_item_code: row.company_item_code,
        id: row.id,
        delete: del,
      }
    },

    fetchItems(filters: CompanyItemFilter) {
      // this won't happen if we are a customer
      const company = filters?.customer?.cmp_wwn
      const item = filters?.item?.ItemCode
      if (company || item) {
        return this.api_call(FetchCompanyItemDocument, {
          company: company,
          item: item,
        }).then((company_items: AxiosResponse) => {
          return company_items.data.data.company_item.edges.map((obj: CompanyItemNode) =>
            this.parseCompanyItemNode(obj.node)
          )
        })
      } else {
        return null
      }
    },
    parseCompanyItemNode(node: CompanyItemField): CompanyItem {
      const item = parseItem(node.item)
      return {
        ...item,
        company: node.company.cmp_wwn,
        company_item_code: node.company_item_code,
        id: node.id,
        debnr: node.company.debnr,
        cmp_name: node.company.cmp_name,
        CoulisseCompanyCode: node.company.CoulisseCompanyCode,
        sysmodified: node.sysmodified,
        sysmodifier: node.sysmodifier.email,
        syscreated: node.syscreated,
        syscreator: node.syscreator.email,
      }
    },
  },
}
</script>
