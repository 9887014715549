<template>
  <div>
    <h1>Customer collection</h1>
    <p>On this page you can view and update your existing collection.</p>

    <AddToCollectionModal
      v-if="customer"
      title="Add collection row"
      name="add_data_modal"
      :callback="add_data_callback"
      :account-code="customer.cmp_wwn"
      :mutate="(newCollection) => mutateCollections([newCollection])"
    />

    <template v-if="$store.state.user.is_customer || $store.state.user.is_employee">
      <i-table
        ref="ITable"
        :fetch-items="fetchItems"
        :mutate-items="mutateCollections"
        :fields="fields"
        :actions="actions"
        app-name="collection"
        :allowed-selectors="allowed_selectors"
        @update:selectBy="(v) => (select_by = v)"
      >
        <!--        todo: add help slot?-->
      </i-table>
    </template>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { parseItem } from '@/common/parseItem'
import useTableDefaultFields, { DefaultFieldGroups } from '@/components/table/useTableDefaultFieldsCustomer'
import { FetchCollectionDocument, MutateCollectionDocument } from '@/graphql/generated'
import AddToCollectionModal from '@/views/collection/AddToCollectionModal.vue'

export default {
  /* eslint-disable-next-line vue/multi-word-component-names*/
  name: 'Collection',
  components: { AddToCollectionModal },
  mixins: [api_mixin, utils],
  data() {
    return {
      select_by: null,
      fields: [],
      collectionFields: [
        {
          key: 'ItemCodeAccount',
          selected: true,
          editable: true,
        },
        {
          key: 'CollectionName',
          selected: true,
          editable: true,
        },
        {
          key: 'CollectionStart',
          selected: true,
          editable: this.$store.state.user.is_employee,
        },
        {
          key: 'CollectionEnd',
          selected: true,
          editable: this.$store.state.user.is_employee,
        },
      ],
    }
  },
  computed: {
    allowed_selectors() {
      if (this.$store.state.user.is_employee) {
        return [['customer', 'item']]
      } else {
        return []
      }
    },
    actions() {
      return [
        {
          key: 'add_data',
          execute_global: this.select_by && this.select_by.customer ? this.showAddDataModal : null,
        },
      ]
    },
    customer() {
      return this.select_by?.customer
    },
  },
  mounted() {
    if (this.$store.state.user.is_customer) {
      this.select_by = { selected: 'customer', customer: this.$store.state.company }
    }
    this.fields = useTableDefaultFields(
      [DefaultFieldGroups.ItemAttributes, DefaultFieldGroups.CustomerAttributes, DefaultFieldGroups.SystemInfo],
      this.collectionFields
    )
  },
  methods: {
    add_data_callback(data) {
      this.$refs.ITable.addItem(data)
    },
    parseCollectionNode(node) {
      const item = parseItem(node.ItemCode)
      return {
        ...item,
        // parseCollecionNode? similar to Forecast.parseRollingFC. You need this functionality elsewhere right?
        ItemCodeAccount: node.ItemCodeAccount,
        CollectionName: node.CollectionName,
        CollectionStart: node.CollectionStart,
        CollectionEnd: node.CollectionEnd,
        id: node.id,
        AccountCode: node.AccountCode.cmp_wwn,
        debnr: node.AccountCode.debnr,
        cmp_name: node.AccountCode.cmp_name,
        CoulisseCompanyCode: node.AccountCode.CoulisseCompanyCode,
        syscreated: node.syscreated,
        syscreator: node.syscreator.email,
        sysmodified: node.sysmodified,
        sysmodifier: node.sysmodifier.email,
      }
    },
    fetchItems(filters) {
      // this won't happen if we are a customer
      const account = filters?.customer?.cmp_wwn
      const item = filters?.item?.ItemCode
      if (item || account) {
        return {
          query: FetchCollectionDocument,
          parameters: { account: account, item: item },
          after: (response) => response.data.data.collection.edges.map((obj) => this.parseCollectionNode(obj.node)),
        }
      } else {
        return null
      }
    },
    parametersFromRow(row) {
      return {
        account_code: row.AccountCode,
        item_code: row.ItemCode,
        item_code_account: row.ItemCodeAccount,
        collection_name: row.CollectionName,
        collection_start: row.CollectionStart,
        collection_end: row.CollectionEnd,
        id: row.id,
      }
    },
    async mutateCollections(modifiedCollections, removedCollections = []) {
      const updatePromises = modifiedCollections.map((modifiedCollection) => {
        return this._mutateCollection(modifiedCollection, false)
      })
      const deletePromises = removedCollections.map((removedCollection) => {
        return this._mutateCollection(removedCollection, true)
      })
      const responses = await Promise.all([...updatePromises, ...deletePromises])
      const successful = []
      let errors = []
      responses.forEach((response) => {
        if (response !== null) {
          if (response.errors) {
            errors = errors.concat(response.errors)
          } else {
            successful.push(response)
          }
        } // response being null indicates a successful deletion
      })
      return { errors: errors, successful: successful }
    },
    async _mutateCollection(item, del) {
      const parameters = this.parametersFromRow(item)
      parameters.delete = del
      const response = await this.api_call(MutateCollectionDocument, parameters)
      if (response.data.errors) {
        return response.data
      } else if (!del) {
        return this.parseCollectionNode(response.data.data.MutateCollection.Collection)
      } else {
        return null // item was successfully deleted
      }
    },
    async showAddDataModal() {
      this.$bvModal.show('add_data_modal')
    },
  },
}
</script>

<style scoped></style>
