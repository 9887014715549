<template>
  <!--  done: edit_lock implementation -->
  <div v-if="$store.state.user.is_tester">
    <h1>Manage forecast</h1>
    <p>On this page you can view and update the customer sales forecast.</p>

    <AddToForecastModal
      v-if="customer"
      id="add_data_modal"
      title="Add Rolling forecast"
      :mutate="MutateRollingFCs"
      :cmp_wwn="customer.cmp_wwn"
      :callback="add_data_callback"
    />
    <!-- keep this modal defined in the controller/defaultwrapper,
     since it modifies the underlying data. don't move it to the table component -->
    <i-table
      ref="ITable"
      :fetch-items="fetchItems"
      :fields="fields"
      :actions="actions"
      :allowed-selectors="selectors"
      app-name="forecast"
      :mutate-items="MutateRollingFCs"
      @update:selectBy="(v) => (select_by = v)"
      @update:tableMode="(tm) => (tableMode = tm)"
    >
      <template v-if="tableMode === TABLE_MODE.WRITE" #cell(rolling_fc)="row">
        <typed-form-input v-model="row.item.rolling_fc" :type="Number" :disabled="row.item.forecast_type !== 'MANUAL'">
          {{ row.item.rolling_fc }}
        </typed-form-input>
      </template>

      <template #cell(forecast_type)="row">
        <template v-for="button in forecast_types">
          <b-button
            v-if="row.item.forecast_type === button.value"
            :key="button.value"
            :disabled="tableMode === TABLE_MODE.READ"
            pill
            :variant="button.variant"
            @click="row.item.forecast_type = button.click"
          >
            {{ button.text }}
          </b-button>
        </template>
      </template>

      <template #help-tab>
        <ForecastHelp />
      </template>

      <template #head(expected_growth)="field">
        <span v-b-tooltip.hover :title="EG_tooltip">{{ field.label }}</span>
      </template>

      <template #head(sold_past_year)="field">
        <span v-b-tooltip.hover :title="sold_past_year_tooltip">{{ field.label }}</span>
      </template>

      <template #cell(salesquantity)="row">{{ row.item.standard_package_quantity + ' ' + row.item.unit }}</template>

      <template #row-details="row">
        <b-card>
          <monthly-forecast
            v-model="row.item"
            :allow-edit="tableMode !== TABLE_MODE.WRITE"
            :mutate-function="MutateRollingFC"
            :start-date="new Date(select_by.rolling_fc_date)"
            @edit-attribute="
              (key, value) => {
                row.item[key] = value
              }
            "
            @monthlyfc-counter-increase="increaseMonthlyFCCounter"
            @hide="increaseMonthlyFCCounter()"
          />
        </b-card>
      </template>
    </i-table>
  </div>
</template>

<script lang="ts">
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import {
  FetchForecastDocument,
  ForecastType, // InitializeForecastDocument,
  MutateRollingForecastsDocument,
} from 'innicore/graphql/generated'
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'
import AddToForecastModal from 'innicore/views/forecast/AddToForecastModal.vue'
import ForecastHelp from 'innicore/views/forecast/ForecastHelp.vue'

import { parseItem } from '@/common/parseItem'
import useTableDefaultFields, { DefaultFieldGroups } from '@/components/table/useTableDefaultFieldsCustomer'

export default {
  components: { AddToForecastModal, ForecastHelp },
  mixins: [api_mixin, utils],
  data() {
    return {
      fields: [],
      extraFields: [
        {
          key: 'rolling_fc',
          label: 'Rolling forecast',
          sortable: true,
          optional: true,
          selected: true,
          editable: true,
          type: Number,
          formatter: (value) => Number(value),
        },
        {
          key: 'rolling_fc_m2',
          label: 'Rolling forecast (m2)',
          sortable: true,
          optional: true,
          type: Number,
          formatter: (value) => Number(value),
        },
        {
          key: 'sold_past_year',
          label: 'Sold past 12 months',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          formatter: (value) => Number(value),
        },
        {
          key: 'expected_growth',
          label: 'EG',
          checkbox_label: 'Expected growth',
          sortable: true,
          optional: true,
          selected: true,
          type: Number,
          aggregations: ['avg', 'min', 'max'],
          formatter: (value) => `${this.format_num(value * 100, 1)} %`,
        },
        {
          key: 'is_ml_fc',
          label: 'Is ML forecast',
          sortable: true,
          optional: true,
          formatter: (value) => (value ? 'Yes' : 'No'),
          type: Boolean,
        },
        {
          key: 'last_manual_edit',
          label: 'Last manual edit',
          optional: true,
          selected: false,
          editable: false,
          type: Date,
        },
        {
          key: 'forecast_type',
          label: 'Forecast Type',
          sortable: true,
          optional: true,
          selected: true,
          editable: true,
          type: String,
        },
        // Financial forecast
        {
          key: 'agreed_price',
          label: 'Agreed price',
          sortable: true,
          optional: true,
          formatter: this.formatPrice,
        },
        {
          key: 'default_price',
          label: 'Default price',
          sortable: true,
          optional: true,
          formatter: this.formatPrice,
        },
        {
          key: 'cost_price',
          label: 'Cost price',
          sortable: true,
          optional: true,
          formatter: this.formatPrice,
        },
        {
          key: 'gross_revenue',
          label: 'Gross revenue',
          sortable: true,
          optional: true,
          formatter: this.formatMoney,
        },
        {
          key: 'gross_profit',
          label: 'Gross profit',
          sortable: true,
          optional: true,
          formatter: this.formatMoney,
        },
        {
          key: 'gross_profit_margin',
          label: 'Gross profit margin',
          sortable: true,
          optional: true,
          formatter: this.formatFraction,
        },
      ],
      items: [],
      tableMode: null,
      items_backup: [],
      removed_items: [],
      add_data_modal: {
        id: 'add_data_modal',
        title: 'Add Rolling forecast',
        on_ok: this.handleOK,
        on_cancel: this.handleCancel,
        on_hidden: this.resetModal,
        item: null,
        rolling_fc: null,
        errors: [],
      },
      edit_lock_error: {
        show: false,
      },
      bulk_edit: {},
      monthlyfc_counter: 0,
      bulk_update_rolling_fc: null,
      select_by: {},
      forecast_types: [
        {
          value: ForecastType.MANUAL,
          text: 'Manual forecast',
          variant: 'warning',
          click: ForecastType.LINEAR_REGRESSION,
        },
        {
          value: ForecastType.LINEAR_REGRESSION,
          text: 'Machine Learning',
          variant: 'success',
          click: ForecastType.MANUAL,
        },
        // Temporarily disable ML untill it's proven better than Linear regression.
        // { value: ForecastType.MACHINE_LEARNING, text: 'Machine learning',
        // variant: 'danger', click: ForecastType.MANUAL },
      ],
      euroFormatter: Intl.NumberFormat('en-DE', { style: 'currency', currency: 'EUR' }),
    }
  },
  computed: {
    selectors() {
      const customer = this.$store.getters.getCompanyById(this.$route.query.company)
      const item = this.$store.getters.getItemByItemCode(this.$route.query.item)
      const rolling_fc_date = { rolling_fc_date: this.$route.query.fc_date }

      return [[{ customer }, { item }], [{ rolling_fc_date }]]
    },
    actions() {
      return [
        {
          key: 'toggle_details',
          title: 'Toggle monthly details',
          icon: 'calendar2-month',
          disallow: false,
        },
        {
          key: 'calc_rolling_fc',
          title: 'Calculate rolling forecast',
          icon: 'calculator',
          modes: [TABLE_MODE.WRITE],
          variant: 'secondary',
          execute: (obj) => (obj.rolling_fc = Math.round(obj.sold_past_year * (1 + obj.expected_growth))),
        },
        {
          key: 'forecast_history',
          title: 'Show forecast history',
          icon: 'clock-history',
          variant: 'violet',
          execute: (obj) => {
            const route = this.$router.resolve({
              name: 'ForecastHistory',
              query: { company: obj.cmp_wwn, item: obj.item },
            })
            window.open(route.href, '_blank')
          },
        },
        // Add data modal only works if select by is Customer, that why we disallow if else.
        // You could also fix that the modal works for another select by. But currently I don't think its worth it.
        {
          key: 'add_data',
          execute_global: this.showAddDataModal,
          disallow: !this.select_by || !this.select_by.customer,
        },
        {
          key: 'edit',
          disabled: !this.editing_monthlyfc || !this.fetched_current_fc,
          disabled_message: !this.editing_monthlyfc
            ? 'You cannot edit rolling forecasts while editing monthly forecasts.'
            : 'You cannot edit old rolling forecasts.',
        },
      ]
    },
    editing_monthlyfc() {
      return this.monthlyfc_counter === 0
    },
    fetched_current_fc() {
      return this.get_current_fc_date() === this.select_by?.rolling_fc_date
    },
    customer() {
      return this.select_by?.customer
    },
    EG_tooltip() {
      return (
        'This is the computed expected sales growth, compared to what has been sold in the past 12 months. ' +
        'The Computed expected growth is calculated using a linear regression, ' +
        'for more information open the monthly details and see its graph.'
      )
    },
    sold_past_year_tooltip() {
      const today = new Date()
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      return `The sales quantity (of the item's default sales unit) from ${
        months[new Date(this.select_by.rolling_fc_date).getMonth()]
      } ${new Date(this.select_by.rolling_fc_date).getFullYear() - 1} to
      ${months[(today.getMonth() + 11) % 12]} ${today.getFullYear()}`
    },
    TABLE_MODE() {
      return TABLE_MODE
    },
    // Generate an edit lock name based on the type of data edited and for which customer
  },
  mounted() {
    if (!this.$store.state.user.is_employee) {
      // Dit is niet nice denk ik. Je ziet deze louter in de console? Auth checking obv router??
      throw 'Not allowed on this page'
    }
    this.fields = useTableDefaultFields(
      [DefaultFieldGroups.CustomerAttributes, DefaultFieldGroups.ItemAttributes, DefaultFieldGroups.SystemInfo],
      this.extraFields
    )
  },
  methods: {
    getQuery() {
      return FetchForecastDocument
    },
    getMutation() {
      return MutateRollingForecastsDocument
    },
    parseRollingFC(obj) {
      const rollingFC = this.defaultParseRollingFC(obj)
      return {
        ...rollingFC,
        ItemCodeAccount: obj.CollectionNode?.ItemCodeAccount,
        CollectionEnd: obj.CollectionNode?.CollectionEnd,
        CollectionName: obj.CollectionNode?.CollectionName,
        CollectionStart: obj.CollectionNode?.CollectionStart,
      }
    },
    fetchItems(filters) {
      const parameters = {}
      parameters.cmp_wwn = filters?.customer?.cmp_wwn
      parameters.itemcode = filters?.item?.ItemCode
      parameters.fc_date = filters?.rolling_fc_date
      if ((parameters.itemcode || parameters.cmp_wwn) && parameters.fc_date) {
        return {
          query: this.getQuery(),
          parameters: parameters,
          after: (response) => response.data.data.RollingFC.edges.map((obj) => this.parseRollingFC(obj.node)),
        }
      }
      return null
    },
    add_data_callback(data) {
      this.$refs.ITable.addItem(data)
    },
    defaultParseFinancialForecast(obj) {
      return {
        agreed_price: obj.financial_forecast?.agreed_price,
        default_price: obj.financial_forecast?.default_price,
        cost_price: obj.financial_forecast?.cost_price,
        gross_revenue: obj.financial_forecast?.gross_revenue,
        gross_profit: obj.financial_forecast?.gross_profit,
        gross_profit_margin: obj.financial_forecast?.gross_profit_margin,
      }
    },
    defaultParseRollingFC(obj) {
      const item = parseItem(obj.ItemCode)
      const financial_forecast = this.defaultParseFinancialForecast(obj)
      return {
        ...item,
        ...financial_forecast,

        debnr: obj.AccountCode.debnr,
        cmp_name: obj.AccountCode.cmp_name,
        cmp_wwn: obj.AccountCode.cmp_wwn,
        CoulisseCompanyCode: obj.AccountCode.CoulisseCompanyCode,
        rolling_fc: Number(obj.rolling_fc),
        sold_past_year: Math.round(obj.sold_past_year),
        expected_growth: obj.expected_growth,
        forecast_type: obj.forecast_type,
        // Dict comprehension in javascript B)
        _monthlyfc: Object.fromEntries(
          Object.entries(obj)
            .filter(([key]) => key.startsWith('month'))
            .map(([key, value]) => [key, Number(value)])
        ),
        id: obj.id,
        rolling_fc_m2: Math.floor((obj.rolling_fc * Number(obj.ItemCode.width)) / 100),
        last_manual_edit: obj.last_manual_edit,
        syscreated: obj.syscreated,
        syscreator: obj.syscreator.email,
        sysmodified: obj.sysmodified,
        sysmodifier: obj.sysmodifier.email,
      }
    },

    parametersFromRow(row, del) {
      return {
        company: row.cmp_wwn,
        is_ml_fc: row.is_ml_fc,
        rolling_fc: row.rolling_fc,
        item: row.item,
        forecast_type: row.forecast_type,
        rfc_id: row.id,
        monthly_forecast: row._monthlyfc,
        delete: del,
      }
    },
    MutateRollingFCs(items, removed_items = []) {
      let mutation_input = items.map((row) => this.parametersFromRow(row, false))
      const remove_input = removed_items.map((row) => this.parametersFromRow(row, true))
      mutation_input = mutation_input.concat(remove_input)
      return this.api_call(this.getMutation(), { input: mutation_input }).then((response) => {
        let errors = []
        if (response.data.errors) {
          errors = errors.concat(response.data.errors)
        }
        if (response.data.data?.MutateRollingForecasts.RollingForecasts.errors?.length) {
          errors = errors.concat(response.data.data.MutateRollingForecasts.RollingForecasts.errors)
        }
        const fields = response.data.data ? response.data.data.MutateRollingForecasts.RollingForecasts : []
        return { errors: errors, successful: fields.map((item) => this.parseRollingFC(item)) }
      })
    },
    MutateRollingFC(item, enable_ml = false) {
      const item_input = this.parametersFromRow(item)
      if (enable_ml) {
        item_input.forecast_type = ForecastType.LINEAR_REGRESSION
      }
      return this.api_call(this.getMutation(), { input: [item_input] }).then((response) => {
        Object.entries(this.parseRollingFC(response.data.data.MutateRollingForecasts.RollingForecasts[0])).forEach(
          (entry) => (item[entry[0]] = entry[1])
        )
      })
    },
    async showAddDataModal() {
      if (this.add_data_modal) {
        this.$bvModal.show(this.add_data_modal.id)
      } else {
        console.error('add_data_modal is undefined! Pass the ID of a modal as add_data_modal to Fancy Table component')
      }
    },
    resetModal() {
      this.add_data_modal.item = null
      this.add_data_modal.rolling_fc = null
      this.add_data_modal.errors = []
    },
    // Ik denk dat dit in de FancyTable wrapper kan
    increaseMonthlyFCCounter(value) {
      this.monthlyfc_counter += value
    },
    formatPrice(value) {
      if (value == null || isNaN(Number(value))) {
        return '-'
      }
      return `€${Number(value).toFixed(4)}`
    },
    formatMoney(value) {
      if (value == null || isNaN(Number(value))) {
        return '-'
      }
      return this.euroFormatter.format(value)
    },
    formatFraction(value) {
      if (value == null || isNaN(Number(value))) {
        return '-'
      }
      return `${Number(value * 100).toFixed(1)}%`
    },
  },
}
</script>
