<template>
  <div v-if="$store.state.user.is_tester">
    <h1>Company Forecast Overview</h1>
    <p>On this page you can view a forecast of each company. To see more per company, click on that company</p>

    <i-table
      ref="ITable"
      app-name="company-forecast"
      :fetch-items="fetchItems"
      :fields="fields"
      :actions="actions"
      :allowed-selectors="[['rolling_fc_date']]"
      @update:selectBy="(v) => (select_by = v)"
    >
      <template #cell(cmp_name)="row">
        <router-link v-b-popover.hover.top="'View company forecast'" :to="viewMoreRoute(row.item)">
          {{ row.item.cmp_name }}
        </router-link>
      </template>
    </i-table>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { FetchCompanyForecastOverviewDocument } from '@/graphql/generated'
import { router } from '@/router'

export default {
  mixins: [api_mixin, utils],
  data() {
    return {
      fields: [
        { key: 'cmp_name', type: String, sortable: true, label: 'Company', selected: true },
        { key: 'debnr', label: 'Debnr', type: Number, sortable: true, aggregations: [], selected: true },
        { key: 'gross_revenue', label: 'Gross revenue', type: Number, sortable: true, formatter: this.formatMoney },
        { key: 'gross_profit', label: 'Gross profit', type: Number, sortable: true, formatter: this.formatMoney },
        {
          key: 'gross_profit_margin',
          label: 'Gross profit margin',
          type: Number,
          sortable: true,
          aggregations: ['avg', 'min', 'max'],
          formatter: this.formatFraction,
        },
      ],
      extraFields: [
        { key: 'cmp_wwn', label: 'Unique id', type: String, sortable: true, selected: false },
        { key: 'fc_date', label: 'FC date', type: Date, sortable: true, formatter: this.format_fc_date },
        {
          key: 'last_manual_edit',
          label: 'Last manual edit',
          type: Date,
          sortable: true,
          formatter: (d) => this.format_date_time(new Date(d)),
        },
        {
          key: 'rollingforecast_count',
          label: 'Rolling forecast count',
          type: Number,
          sortable: true,
          formatter: (i) => this.format_num(i, 0),
        },
      ],
      actions: [
        {
          key: 'overview',
          title: 'View company forecast',
          icon: 'eye',
          variant: 'b-blue',
          execute: (item) => router.push(this.viewMoreRoute(item)),
        },
      ],
      select_by: null,
      euroFormatter: Intl.NumberFormat('en-DE', { style: 'currency', currency: 'EUR' }),
    }
  },
  computed: {
    fc_date() {
      return this.select_by?.rolling_fc_date
    },
  },
  methods: {
    viewMoreRoute(item) {
      const query = { company: item.cpm_wwn }
      if (this.fc_date) query.fc_date = this.fc_date
      return { name: 'company-item-forecast', query }
    },
    parseCompanyForecastOverview(obj) {
      let gross_profit_margin = null
      if (obj.gross_revenue && obj.gross_profit) {
        gross_profit_margin = obj.gross_profit / obj.gross_revenue
      }
      return {
        cpm_wwn: obj.company.cmp_wwn,
        cmp_name: obj.company.cmp_name,
        debnr: obj.company.debnr,
        CoulisseCompanyCode: obj.company.CoulisseCompanyCode,
        fc_date: obj.fc_date,
        last_manual_edit: obj.last_manual_edit,
        gross_revenue: obj.gross_revenue,
        gross_profit: obj.gross_profit,
        gross_profit_margin,
        rollingforecast_count: obj.rollingforecast_count,
      }
    },
    fetchItems() {
      if (!this.fc_date) return []
      return this.api_call(FetchCompanyForecastOverviewDocument, { fc_date: this.fc_date }).then((response) => {
        return response.data.data.CompanyForecastOverview.edges.map((edge) =>
          this.parseCompanyForecastOverview(edge.node)
        )
      })
    },
    formatPrice(value) {
      if (value == null || isNaN(Number(value))) {
        return '-'
      }
      return `€${Number(value).toFixed(4)}`
    },
    formatMoney(value) {
      if (value == null || isNaN(Number(value))) {
        return '-'
      }
      return this.euroFormatter.format(value)
    },
    formatFraction(value) {
      if (value == null || isNaN(Number(value))) {
        return '-'
      }
      return `${Number(value * 100).toFixed(1)}%`
    },
  },
}
</script>
